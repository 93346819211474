<script>
    import TitleSection from "./TitleSection.svelte";
    import Pagination from "./Pagination.svelte";
    import { writable } from "svelte/store";
    import { afterUpdate, onMount } from "svelte";

    export let title = {
        en: "",
        mm: ""
    };

    export let images = [];
    export let featuredImage = {};
    export let itemsPerPage = 9;
    let paginatedImages = [];
    const currentPage = writable(1);

    function paginateImages() {
        const start = ($currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        console.log("Paginated Images: ", images.slice(start, end));
        paginatedImages = images.slice(start, end);
        return paginatedImages;
    }
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
        itemsPerPage = 4;
    }

    if (Object.keys(featuredImage).length == 0) {
        itemsPerPage = 12;
    }

    onMount(paginateImages);
    afterUpdate(paginateImages);
</script>

<TitleSection
    title={title}
    backgroundColor={'var(--secondary-color)'}
    textColor={'var(--primary-color)'}
    id="quotes"
/>

<div class="gallery">
    {#if isMobile}
        {#if Object.keys(featuredImage).length > 0}
            <a class="image-container large" href={featuredImage.link} target="_blank">
                <img src={featuredImage.image} alt="Gallery Image" />
            </a>
        {/if}
        {#each paginatedImages as image}
            <a class="image-container large" href={image.link} target="_blank">
                <img src={image.image} alt="Gallery Image" />
            </a>
        {/each}
    {:else}
        {#if Object.keys(featuredImage).length > 0}
            <a class="image-container large" href={featuredImage.link} target="_blank">
                <img src={featuredImage.image} alt="Gallery Image" class="large-img"/>
            </a>
        {/if}
        {#each paginatedImages as image}
            <a class="image-container medium" href={image.link} target="_blank">
                <img src={image.image} alt="Gallery Image" />
            </a>
        {/each}
    {/if}
</div>

<Pagination
    totalItems={images.length}
    itemsPerPage={itemsPerPage}
    currentPage={currentPage}
/>

<style>
    .gallery {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 20px; /* Adjust gap for more spacing between images */
    }

    @media (max-width: 768px) {
        .gallery {
            grid-template-columns: repeat(4, 1fr);
            gap: 10px; /* Adjust gap for mobile view */
        }
    }

    .image-container {
        margin: 10px; /* Add margin for spacing */
        box-sizing: border-box;
    }

    @media (max-width: 768px) {
        .image-container {
            border-radius: 10px;
            margin: 10px; /* Add margin for spacing */
            overflow: hidden;
        }
    }

    .large {
        grid-column: span 6;
        grid-row: span 3;
    }

    @media (max-width: 1024px) {
        .large {
            grid-column: span 4;
            grid-row: span 2;
        }
    }

    @media (max-width: 768px) {
        .large {
            grid-column: span 4;
            grid-row: span 2;
        }
    }

    @media (max-width: 768px) {
        .large {
            grid-column: span 4;
            grid-row: span 2;
        }
    }

    .medium {
        grid-column: span 3;
        grid-row: span 1;
    }

    @media (max-width: 768px) {
        .medium {
            grid-column: span 2;
            grid-row: span 1;
        }
    }

    .small {
        grid-column: span 2;
        grid-row: span 1;
    }

    .large-img {
        width: 904px !important;
        height: 620px !important;
        object-fit: cover;
    }

    .image-container img {
        min-width: 100%;
        height: 196px;
        object-fit: cover;
        border-radius: 10px;
    }

    @media (max-width: 768px) {
        .image-container img {
            width: 100%;
            height: 100%;
        }
    }
</style>
