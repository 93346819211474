<script>
    import { currentLanguage } from "../stores/languageStore";
    import TestimonialModal from './TestimonialModal.svelte';
    import { onMount } from 'svelte';

    export let testimonial = {
        title: { en: "", mm: "" },
        description: { en: "", mm: "" },
        name: "",
        position: "",
        image: ""
    };

    let showModal = false;

    function toggleModal() {
        showModal = !showModal;
    }

    $: shortDescription = $currentLanguage === 'en'
        ? testimonial.description.en.slice(0, 300) + (testimonial.description.en.length > 300 ? '...' : '')
        : testimonial.description.mm.slice(0, 300) + (testimonial.description.mm.length > 300 ? '...' : '');
</script>

<div class="testimonial-card" on:click={toggleModal}>
    <h3 class="testimonial-title">{testimonial.title.en ? testimonial.title.en : ''}</h3>
    <p class="testimonial-description">{shortDescription ? shortDescription : ''}</p>
    <div class="testimonial-footer">
        <img src={testimonial.image} alt={testimonial.name} class="testimonial-image" />
        <div class="testimonial-info">
            <p class="testimonial-name">{testimonial.name ? testimonial.name : ''}</p>
            <p class="testimonial-position">{testimonial.position ? testimonial.position : ''}</p>
            <p class="testimonial-position">{testimonial.organization ? testimonial.organization : ''}</p>
        </div>
    </div>

    {#if showModal}
        <TestimonialModal {testimonial} onClose={toggleModal} />
    {/if}
</div>

<style>
    .testimonial-card {
        background-color: var(--primary-color);
        border-radius: 20px;
        padding: 20px;
        color: var(--secondary-color);
        display: grid;
        grid-template-rows: auto 1fr auto;
        gap: 10px;
        width: 100%;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        .testimonial-card {
            padding: 10px;
        }
    }

    .testimonial-title {
        font-family: "Roboto Flex", sans-serif;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        .testimonial-title {
            font-size: 16px;
        }
    }

    .testimonial-description {
        font-family: "Roboto Flex", sans-serif;
        font-size: 16px;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        .testimonial-description {
            font-size: 14px;
        }
    }

    .testimonial-footer {
        display: flex;
        align-items: center;
        background-color: var(--primary-color);
        border-radius: 0 0 20px 20px;
        padding: 10px;
    }

    @media (max-width: 768px) {
        .testimonial-footer {
            padding: 5px;
        }
    }

    .testimonial-image {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-right: 10px;
    }

    @media (max-width: 768px) {
        .testimonial-image {
            width: 30px;
            height: 30px;
        }
    }

    .testimonial-info {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 768px) {
        .testimonial-info {
            flex: 1;
        }
    }

    .testimonial-name {
        font-family: "Roboto Flex", sans-serif;
        font-size: 14px;
        font-weight: bold;
        margin: 0;
    }

    @media (max-width: 768px) {
        .testimonial-name {
            font-size: 12px;
        }
    }

    .testimonial-position {
        font-family: "Roboto Flex", sans-serif;
        font-size: 12px;
        margin: 0;
    }

    @media (max-width: 768px) {
        .testimonial-position {
            font-size: 10px;
        }
    }
</style>
