<script>
    import { currentLanguage } from "../stores/languageStore";
    import { onMount } from "svelte";
    import { urlRoot } from "../constants";

    export let translations = {};

    let banner_image = null;
    let mobile_banner_image = null;
    let isMobile = false;

    // Detect if the current device is mobile
    function updateIsMobile() {
        isMobile = window.innerWidth <= 768;
    }

    async function fetchBanner() {
        const response = await fetch(`${urlRoot}/banners`);
        const data = await response.json();
        if (data.home_banner_image) {
            banner_image = `${urlRoot}/files/${data.home_banner_image}`;
        }
        if (data.mobile_home_banner_image) {
            mobile_banner_image = `${urlRoot}/files/${data.mobile_home_banner_image}`;
        }
        console.log("Desktop Banner:", banner_image);
        console.log("Mobile Banner:", mobile_banner_image);
    }

    onMount(() => {
        fetchBanner();
        updateIsMobile();
        window.addEventListener("resize", updateIsMobile);

        return () => {
            window.removeEventListener("resize", updateIsMobile);
        };
    });
</script>

<section
    class="hero"
    style="background-image: url({isMobile && mobile_banner_image ? mobile_banner_image : banner_image ? banner_image : '/assets/img/hero.jpeg'})"
>
    <div class="content">
        <h1>{$currentLanguage === 'en' ? translations.en.heading : translations.mm.heading}</h1>
        <a class="cta" href={translations.link}>
            <div class="circle">
                <img src="/assets/img/icons/arrow.svg" alt="Arrow" />
            </div>
            <span>{$currentLanguage === 'en' ? translations.en.button : translations.mm.button}</span>
        </a>
    </div>
</section>

<style>
    .hero {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 635px;
        display: flex;
        align-items: end;
        justify-content: flex-start;
        border-radius: 20px;
        margin-top: 10px;
    }

    .content {
        background: var(--primary-color);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        width: 864px;
        padding: 35px 36px;
        color: var(--secondary-color);
        margin-left: 42px;
        margin-bottom: 42px;
    }

    h1 {
        font-family: "Roboto Flex", sans-serif;
        font-size: 30px;
        font-weight: 600;
        line-height: 45px;
        margin: 0;
        color: #f9efec;
    }

    a {
        color: var(--secondary-color);
        text-decoration: none;
    }

    .cta {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        border-radius: 50px;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Roboto Flex", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: var(--secondary-color);
        margin-top: 20px;
    }

    .circle {
        background: var(--tertiary-color);
        border-radius: 50%;
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .cta img {
        width: 24px;
        height: 24px;
    }

    @media (max-width: 768px) {
        .hero {
            height: 100vh;
            margin-top: 10px;
        }

        .content {
            width: 100%;
            border-radius: 0;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: center;
            border-radius: 0 0 20px 20px;
        }

        h1 {
            font-size: 24px;
            line-height: 36px;
            text-align: center;
        }

        .cta {
            margin-top: 20px;
        }
    }

    @media (max-width: 1024px) {
        .content {
            width: 100%;
            border-radius: 0;
            margin: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: end;
            justify-content: center;
            border-radius: 0 0 20px 20px;
        }
    }
</style>
